<template>
  <q-btn>
    <img src="../../assets/icons/context-menu.svg" alt="" />
    <q-menu class="context-menu">
      <q-list>
        <q-item clickable v-close-popup @click="showEditTermDialog(term)">
          Изменить
        </q-item>
        <q-item v-if="canDelete" clickable v-close-popup @click="showDeleteTermDialog(term)">
          Удалить
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script>
import EditTermDialog from '../../dialogs/terms/EditTermDialog.vue'
import DeleteTermDialog from '../../dialogs/terms/DeleteTermDialog.vue'

export default {
  name: 'TermContextMenu',
  props: {
    term: {
      type: Object,
      required: true,
    },
    hook: {
      type: Function,
      required: true,
    },
    view: {
      type: String,
      default: 'menu',
    }
  },
  methods: {
    showEditTermDialog(term) {
      this.$root
        .createDialog({
          component: EditTermDialog,
          componentProps: {
            term: term,
            hook: this.hook,
          },
        })
        .onOk(term => {
          this.$root.showSuccessMessage(`Слово «${term.name}» изменено.`)
        })
    },
    showDeleteTermDialog(term) {
      this.$root
        .createDialog({
          component: DeleteTermDialog,
          componentProps: {
            term: term,
            hook: this.hook,
          },
        })
        .onOk(term => {
          this.$root.showSuccessMessage(`Слово «${term.name}» удалено.`)
        })
    }
  },
  computed: {
    canDelete() {
      return this.$store.state.currentUser.role.id === 'administrator'
    },
  }
}
</script>
