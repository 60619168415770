<template>
  <div v-if="term != null">
    <page-header title="Просмотр слова" back>
      <template v-if="canEdit">
        <term-context-menu v-if="!term.deleted" :term="term" :hook="reload" view="buttons" />
      </template>
    </page-header>

    <q-card v-if="term.deleted" class="q-pa-lg">
      Слово удалёно.
    </q-card>
    <q-card v-else class="term">
      <div class="term__header">
        <div class="term__name">
          {{ term.name }}
        </div>
        <div class="term__actions">
          <q-btn v-if="term.audio" class="action" @click="playAudio(term.audio)" :class="{'audio-playing': playing}">
            <q-icon class="action__icon">
              <slot>
                <img src="../../assets/icons/listen.svg" alt="">
              </slot>
            </q-icon>
            <span class="action__text">Прослушать</span>
          </q-btn>
          <q-btn class="action" @click="toggleBookmark" :loading="bookmarkProcessing">
            <q-icon class="action__icon">
              <slot>
                <img v-if="inBookmarks" src="../../assets/icons/in-bookmarks.svg" alt="">
                <img v-else src="../../assets/icons/to-bookmarks.svg" alt="">
              </slot>
            </q-icon>
            <span class="action__text">{{ bookmarkTitle }}</span>
          </q-btn>
        </div>
      </div>

      <div v-if="term.categories && term.categories.length > 0" class="term__field row">
        <div class="term__field-key">
          Отрасль
        </div>
        <div class="term__field-value">
          <template v-for="(category, index) in term.categories" :key="category.id">
            <template v-if="index > 0">, </template>
            <router-link v-if="canEdit" :to="{name: 'categories_view', params: {id: category.id}}">{{ category.name }}</router-link>
            <template v-else>{{ category.name }}</template>
          </template>
        </div>
      </div>

      <div v-if="canEdit && term.tags && term.tags.length > 0" class="term__field row">
        <div class="term__field-key">
          Тег
        </div>
        <div class="term__field-value">
          <template v-for="(tag, index) in term.tags" :key="tag.id">
            <template v-if="index > 0">, </template><router-link :to="{name: 'tags_view', params: {id: tag.id}}">{{ tag.name }}</router-link>
          </template>
        </div>
      </div>

      <div v-if="term.teaser" class="term__field row">
        <div class="term__field-key">
          Краткое описание
        </div>
        <div class="term__field-value">
          <div v-html="term.teaser" />
        </div>
      </div>

      <div v-if="term.teaserSource" class="term__field term__field--source row">
        <div class="term__field-key">
          Источник краткого описания
        </div>
        <div class="term__field-value" v-html="term.teaserSource" />
      </div>

      <div v-if="term.description" class="term__field row">
        <div class="term__field-key">
          Полное описание
        </div>
        <div class="term__field-value">
          <div v-html="term.description" />
        </div>
      </div>

      <div v-if="term.descriptionSource" class="term__field term__field--source row">
        <div class="term__field-key">
          Источник полного описания
        </div>
        <div class="term__field-value" v-html="term.descriptionSource" />
      </div>

      <div v-if="canEdit" class="term__field row">
        <div class="term__field-key">
          Статус слова
        </div>
        <div class="term__field-value">
          {{ term.published ? 'Опубликован' : 'Не опубликован' }}
        </div>
      </div>
    </q-card>
  </div>
</template>

<script>
import loading from '../../loading.js'
import api from '../../api/api.js'
import PageHeader from '../../blocks/PageHeader.vue'
import TermContextMenu from '../../blocks/terms/TermContextMenu.vue'

const audioPlayer = new Audio()

export default {
  components: {
    PageHeader,
    TermContextMenu,
  },
  setup() {
    return {
      canEdit: ["editor", "administrator"].includes(api.auth.getCurrentUser().role.id),
    }
  },
  async beforeRouteEnter (to, from, next) {
    loading.start()
    const term = await api.terms.find(to.params.id)
    if (!term) {
      next(false)
      return
    }
    next(vm => { vm.term = term })
    loading.stop()
    loading.updateTitle(to.meta.title || '')
  },
  data() {
    return {
      term: null,
      bookmarkProcessing: false,
      playing: false,
    }
  },
  created() {
    if (api.auth.getCurrentUser().role.id === 'student') {
      setTimeout(() => {
        api.analytics.recordTermView(this.term)
      }, 100)
    }

    audioPlayer.onplay = () => {
      this.playing = true
    }
    audioPlayer.onpause = () => {
      this.playing = false
    }
  },
  methods: {
    async reload(term) {
      this.term = term
    },
    playAudio(audio) {
      if (audioPlayer.paused) {
        audioPlayer.src = audio.url
        audioPlayer.play()
      } else {
        audioPlayer.pause()
      }
    },
    toggleBookmark() {
      this.bookmarkProcessing = true
      if (this.term.bookmark) {
        api.bookmarks.delete(this.term.bookmark)
          .then(() => {
            this.term.bookmark = null
            this.$root.showSuccessMessage('Убрано из закладок.')
            this.bookmarkProcessing = false
          })
      } else {
        api.bookmarks.addToBookmarks(this.term)
          .then(bookmark => {
            this.term.bookmark = bookmark
            this.$root.showSuccessMessage('Добавлено в закладки.')
            this.bookmarkProcessing = false
          })
      }
    },
  },
  computed: {
    inBookmarks() {
      return this.term.bookmark !== null
    },
    bookmarkTitle() {
      return this.inBookmarks ? 'Добавлено в Мои закладки' : 'Добавить в Мои закладки'
    },
  }
}
</script>

<style lang="scss">
@import "~@/spa/styles/quasar.variables.scss";

.term {
  &__header {
    display: flex;
    min-height: 80px;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  &__name {
    font-family: Raleway, sans-serif;
    font-weight: 600;
    font-size: 24px;
  }

  &__actions {
    .action {
      font-family: Raleway, sans-serif;
      font-weight: 500;
      font-size: 14px;
      color: #2C7DDC;

      @media (max-width: $breakpoint-xs-max) {
        & {
          height: 34px;
          width: 34px;
          margin-left: 12px;
        }

        .action__text {
          display: none;
        }
      }
    }
  }

  &__field {
    display: flex;
    flex-wrap: nowrap;
    border-top: 2px solid #F5F6FA;

    @media (max-width: $breakpoint-xs-max) {
      flex-direction: column;
    }
  }

  &__field-key {
    padding: 22px 20px;
    min-height: 60px;
    flex: 0 0 200px;
    width: 200px;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.4);

    @media (max-width: $breakpoint-xs-max) {
      flex-basis: auto;
      font-size: 11px;
      padding: 15px 20px 5px;
      min-height: auto;
    }
  }

  &__field--source .term__field-value {
    font-size: 14px;
  }

  &__field-value {
    padding: 19px;
    overflow: hidden;
    @media (max-width: $breakpoint-xs-max) {
      padding: 5px 20px 15px;
    }

    img {
      max-width: 100%;
    }
  }
}

.audio-playing {
  .action__text {
    color: black;
  }
}
</style>
